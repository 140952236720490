import _iframeResizer from "./js/iframeResizer";
import _iframeResizer2 from "./js/iframeResizer.contentWindow";
var exports = {};
const iframeResize = _iframeResizer;
exports = {
  iframeResize: iframeResize,
  iframeResizer: iframeResize,
  // Backwards compatibility
  contentWindow: _iframeResizer2
};
export default exports;
export const iframeResizer = exports.iframeResizer,
  contentWindow = exports.contentWindow;
const _iframeResize = exports.iframeResize;
export { _iframeResize as iframeResize };